import {IValidationErrors} from "./validator";

// CONSIDER MOVING ERROR GENERATOR FUNCTIONS ON validator.ts HERE

export const generateCtaRequiredError = (property: string, dataPath: string, customMessage?: string): IValidationErrors => {
    return {
        keyword: "ctaField",
        dataPath: `${dataPath}`,
        schemaPath: "",
        schema: "",
        parentSchema: "",
        params: {
            missingProperty: `${property}`,
        },
        message: customMessage || "All CTA Label & CTA Url fields are required.",
        data: "",
    };
}
